/* istanbul ignore file */
import { start, registerSpaComponent, ReactRootInitConfig, getShellActions } from '@nab/x-spa-react';
import RootSpa from './components/Spa/RootSpa/RootSpa';
import { getStartConfig } from './utils/config/commonIndexConfig';
import constants, { CONTEXT_BASE_URL, DEFAULT_ROUTE, miniapps } from './utils/constants';
import { initiliazeConfigMgr } from './utils/config-manager';
import { saveBearerToken, savePreferredLoginMethod, saveUserIdentity } from './utils/session';
import './style.css';
import LoginSpaInstance from './components/Spa/Login/Login';

registerSpaComponent('login-identity', LoginSpaInstance);
registerSpaComponent('merchant-portal', RootSpa);

window.addEventListener('load', async () => {
  // prevent the shell from being loaded inside an iframe
  if (window.top !== window) {
    window.top.location.assign(window.location.href);
    return;
  }

  await initiliazeConfigMgr();

  try {
    const commonConfigParams: ReactRootInitConfig = getStartConfig();
    const app = await start(commonConfigParams);

    app.config.actions.addEventListener(constants.USER_AUTHENTICATED, async event => {
      saveBearerToken();
      saveUserIdentity();

      // saving login method after authentication
      if (window.location.pathname === miniapps.LOGIN.NABC.route) {
        savePreferredLoginMethod(constants.LOGIN_METHOD_NABC);
      } else {
        savePreferredLoginMethod(constants.LOGIN_METHOD_IB);
      }
      getShellActions().navigate(`${CONTEXT_BASE_URL}${DEFAULT_ROUTE}`);
    });

    console.info('SPA started successfully');
  } catch (e) {
    console.error('SPA failed to start', e);
  }
});

// To add calc(var(--vh, 1vh) * 100) and having 100vh as fallback
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
const vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});
