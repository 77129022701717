import React, { useContext, useState, useMemo, useEffect } from 'react';
import { EmbeddedMiniApp, ReactSpaContext } from '@nab/x-spa-react';
import { MobileMenuWrapper, LinksWrapper, NavigationWrapper } from './MobileMenu.style';
import {
  ListGroup,
  HelpIcon,
  ProfileIcon,
  ListGroupItem,
  InternalLinkItem,
  LogInIcon,
  LogOutIcon,
  Link,
  H2,
  Button,
  ChevronLeftIcon
} from '@nab/nui-react';
import { getBearerToken, navigateToLogin, getPreferredLoginMethod } from '../../../utils/session';
import constants, { CONTEXT_BASE_URL, miniapps } from '../../../utils/constants';
import { NavManagerMenuItem } from '../PollinateMenu/PollinateMenu';
import { findApp } from '../../../utils/appContext';
import { containsCurrentRoute } from '../PollinateMenu/PollinateMenu';
import { ShellContext } from '../../Spa/GlobalSpa/GlobalSpa';

interface MobileMenuProps {
  menu: NavManagerMenuItem[];
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
  logoutClicked: () => void;
  setError?: (value: string) => void;
  currentRoute: string;
  showPollinateMenu?: boolean;
}

export function MobileMenu({ menu = [], isMenuOpen, setIsMenuOpen, logoutClicked, setError, currentRoute, showPollinateMenu }: MobileMenuProps) {
  const [hasUserInfoError, setHasUserInfoError] = useState<boolean>(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState<NavManagerMenuItem>(null);
  const [noAnimation, setNoAnimation] = useState<boolean>(false);
  const [renderUserInfo, setRenderUserInfo] = useState(false);
  const { actions, context } = useContext(ReactSpaContext);
  const bearerToken = getBearerToken();
  const tabIndex = selectedMenuItem ? -1 : 0;
  const selectedLink = currentRoute?.includes(miniapps.USER_INFO.route) ? 'selectedLink' : '';
  const { merchantAdminAppConfig, merchantsData } = useContext(ShellContext);

  function formatRoute(itemRoute: string) {
    let route = itemRoute;
    if (route && route.indexOf('/#') >= 0) {
      route = route.replace('/#', '#');
    }
    return route;
  }

  async function selectMenuItem(e: Event, item: NavManagerMenuItem) {
    const route = formatRoute(item.route);
    if (item.children && item.children.length > 0) {
      e.preventDefault();
      setSelectedMenuItem(item);
    } else {
      setIsMenuOpen(false);
      if (item.route?.startsWith('/')) {
        e.preventDefault();
        await actions.navigate(`${CONTEXT_BASE_URL}${route}`);
        window.dispatchEvent(new HashChangeEvent('hashchange'));
        setError?.(null);
      }
    }
  }

  // to not show animation initally
  useEffect(() => {
    if(isMenuOpen) {
      setNoAnimation(false);
    }
  }, [isMenuOpen])

  useEffect(() => {
    menu.map((item) => {
      if (containsCurrentRoute(item, currentRoute) && item.children && item.children.length > 0) {
        setSelectedMenuItem(item);
        if (!isMenuOpen) {
          setNoAnimation(true);
        }
      }
    })
  }, [menu])

  /* Setting a delay to 5s for the embedded user info miniapp username. This is so akamaighost does not get
   * triggered from all the token calls at once during login.
   */
  useEffect(() => {
    let timer = setTimeout(() => {setRenderUserInfo(true)}, constants.USER_INFO_DELAY);
    return () => {
      clearTimeout(timer);
    };
  }, [])

  function renderMenuItem(item: NavManagerMenuItem, index: number, selectedClass = 'selected', nonTabbing = true) {
    const route = formatRoute(item.route);
    return (
      <ListGroupItem key={index} className={containsCurrentRoute(item, currentRoute) ? selectedClass : ''}>
        <InternalLinkItem
          label={item.label}
          data-testid={item.description}
          link={`${CONTEXT_BASE_URL}${route}`}
          tabIndex={selectedMenuItem && nonTabbing ? -1 : 0}
          onClick={(e: Event) => selectMenuItem(e, item)}
        />
      </ListGroupItem>
    );
  }

  function mainMenuClassName() {
    if (isMenuOpen) {
      if (!selectedMenuItem) {
        return '';
      }
      return 'closeMainMenu';
    }
    return 'close';
  }

  function subMenuClassName() {
    if (selectedMenuItem) {
      if (isMenuOpen) {
        return 'openSubMenu';
      }
      else if (noAnimation && !isMenuOpen) {
        return 'noAnimation close';
      }
      else return 'close'
    }
    return 'closeSubMenu';
  }

  function navWrapperClassName() {
    if (showPollinateMenu && bearerToken) {
      return 'showMenu';
    } else if (!bearerToken) {
      return 'loggedOut';
    } else {
      return '';
    }
  }

  const merchantSelectorMiniapp = useMemo(() => {
    return merchantAdminAppConfig && merchantsData ? (
      <EmbeddedMiniApp
        appMetaData={merchantAdminAppConfig}
        appConfig={{
          view: 'mobile',
          initialMerchantsData: merchantsData
        }}
      />
    ) : (
      <></>
    );
  }, [merchantAdminAppConfig, merchantsData]);

  const userInfoAppConfig = useMemo(() => {
    return context ? findApp(context, miniapps.USER_INFO.name) : null;
  }, [context]);

  const userInfoMiniApp = useMemo(() => {
    const loginTenant = getPreferredLoginMethod() === constants.LOGIN_METHOD_IB ? 'ib' : 'nabc';
    return userInfoAppConfig ? (
      <EmbeddedMiniApp
        appMetaData={userInfoAppConfig}
        appConfig={{
          view: 'LINK',
          tenant: loginTenant
        }}
        onError={() => setHasUserInfoError(true)}
      />
    ) : (
      <></>
    );
  }, [userInfoAppConfig]);

  return (
    <>
      <MobileMenuWrapper className={mainMenuClassName()} data-testid={'mobileMenuWrapper'}>
        <NavigationWrapper className={navWrapperClassName()}>
          {bearerToken ? (
            <>
              {merchantSelectorMiniapp}
              {menu && showPollinateMenu && <ListGroup id="MenuList">{menu.map((item, index) => renderMenuItem(item, index))}</ListGroup>}
            </>
          ) : (
            <InternalLinkItem
              label="Login"
              icon={() => <LogInIcon color="primary" size="md" />}
              onClick={async () => {
                setIsMenuOpen(false);
                navigateToLogin();
              }}
            />
          )}
        </NavigationWrapper>
        <LinksWrapper className={showPollinateMenu || !bearerToken ? '' : 'linksOnly'}>
          <ListGroup data-testid="bottomLinksWrapper">
            <ListGroupItem>
              <Link
                href="https://www.nab.com.au/business/payments-and-merchants/merchant-support-centre"
                icon={HelpIcon}
                id="helpLinkItem"
                target="_blank"
                rel="noopener noreferrer"
                tabIndex={tabIndex}
              >
                Help
              </Link>
            </ListGroupItem>
            {bearerToken && (
              <ListGroupItem>
                {hasUserInfoError || !renderUserInfo ? (
                  <Link
                    className={selectedLink}
                    data-testid="myDetailsLink"
                    href={miniapps.USER_INFO.route}
                    onClick={async (e: Event) => {
                      e.preventDefault();
                      await actions.navigate(miniapps.USER_INFO.route);
                      setError?.(null);
                    }}
                    icon={ProfileIcon}
                    tabIndex={tabIndex}
                  >
                    My Details
                  </Link>
                ) : (
                  <div
                    className={selectedLink}
                    onClick={() => {
                      setIsMenuOpen(false);
                      setError?.(null);
                    }}
                  >
                    {userInfoMiniApp}
                  </div>
                )}
              </ListGroupItem>
            )}
            {bearerToken && (
              <ListGroupItem>
                <Link
                  icon={LogOutIcon}
                  id="LogoutLink"
                  href={miniapps.LOGIN.SELECTOR.route}
                  onClick={(e: Event) => {
                    e.preventDefault();
                    setIsMenuOpen(false);
                    logoutClicked();
                  }}
                  tabIndex={tabIndex}
                >
                  Logout
                </Link>
              </ListGroupItem>
            )}
          </ListGroup>
        </LinksWrapper>
      </MobileMenuWrapper>
      <MobileMenuWrapper className={subMenuClassName()}>
        {selectedMenuItem && (
          <>
            <Button variant="primary" styledAsLink label="Main menu" icon={ChevronLeftIcon} onClick={() => setSelectedMenuItem(null)} />
            <NavigationWrapper>
              <H2>{selectedMenuItem.label.toUpperCase()}</H2>
              <ListGroup className="children">
                {selectedMenuItem.children.map((child, index) => renderMenuItem(child, index, 'selectedBlack', false))}
              </ListGroup>
            </NavigationWrapper>
          </>
        )}
      </MobileMenuWrapper>
    </>
  );
}

export default MobileMenu;
