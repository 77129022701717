import SystemErrorImage from '../images/system_error.svg';
import TimeoutErrorImage from '../images/timeout_error.svg';
import LoggedoutErrorImage from '../images/logged_out_error.svg';
import { errorsDescriptions } from './errorsDescriptions';
import { RefreshIcon } from '@nab/nui-react';

const constants = {
  TIMEOUT_INTERVAL: 60 * 30 * 1000, // Notes: 30 minutes
  POLLINATE_TIMEOUT_INTERVAL: 60 * 2 * 1000, // Notes: 2 minutes after Pil inactivity modal displayed
  USER_INFO_DELAY: 4 * 1000,

  // Miniapp actions
  USER_AUTHENTICATED: 'user_authenticated',
  POLLINATE_MINIAPP_INACTIVITY: 'pollinate_miniapp_inactivity',
  POLLINATE_MINIAPP_INSIGHTS: 'pollinate_miniapp_insights',
  POLLINATE_MINIAPP_LOADED: 'pollinate_miniapp_loaded',
  POLLINATE_MINIAPP_NAVIGATED: 'pollinate_miniapp_navigated',
  POLLINATE_MINIAPP_SCROLL_TOP: 'pollinate_miniapp_scroll_top',

  POLLINATE_MINIAPP_LOGOUT: 'pollinate_miniapp_logout',
  POLLINATE_MINIAPP_ROLE_CHECK_COMPLETED: 'pollinate_miniapp_role_check_completed',
  MERCHANT_MINIAPP_LOADED: 'merchant_miniapp_loaded',
  MERCHANT_MINIAPP_FACILITY_SELECTED: 'merchant_miniapp_facility_selected',
  MERCHANT_MINIAPP_FACILITY_SELECTED_ANALYTICS: 'merchant_miniapp_facility_selected_analytics',
  CANCEL_SELECTED_MERCHANT: 'cancel_selected_merchant',
  CONFIRM_SELECTED_MERCHANT: 'confirm_selected_merchant',
  OPEN_CONFIRM_MODAL: 'open_confirm_modal',

  USER_INFO_MINIAPP_LOADED: 'user_info_miniapp_loaded',
  WORKFLOW_MANAGEMENT_MINIAPP_LOADED: 'workflow_management_miniapp_loaded',
  PEGA_FORMS_MINIAPP_LOADED: 'pega_forms_miniapp_loaded',
  USERINFO_LINK_MINIAPP_CLICKED: 'user_info_link_miniapp_clicked',
  UAM_MINIAPP_NAVIGATED: 'uim_miniapp_navigated',
  RENDER_ERROR: 'shell_render_error',
  FACILITY_NOT_FOUND: 'merchant_shell_facility_not_found',
  SHELL_MERCHANT_ID_CHANGED: 'shell_merchant_id_changed',
  NAVIGATION: 'shell_navigation',
  MOBILE_MENU_OPEN: 'mobile_menu_open',
  MOBILE_MENU_CLOSE: 'mobile_menu_close',
  MOBILE_MENU_ERROR: 'mobile_menu_error',

  // Session storage
  BEARER_TOKEN: 'bearerToken',
  FACILITY_ID: 'facilityId',
  USER_PROFILE: 'userProfile',
  LOGIN_METHOD: 'preferredLoginMethod',
  LOGIN_METHOD_IB: 'IB',
  LOGIN_METHOD_NABC: 'NABC',
  PREFERRED_LOGIN_METHOD_EXPIRY_DAYS: 90,

  NAB_HIVE_MERCHANT_PORTAL: 'NAB Hive Merchant Portal',
  USER_INACTIVITY_EVENT_MONITORING: false // Notes: Set to "true" to enable tracking user event "click", "keydown" on non-Pollinate pages so we can display timeout warning modal
};

export const LOGIN_URL = '/login';
export const CONTEXT_BASE_URL = '/auth';
export const POLLINATE_MINIAPP_ROUTE = '/portal';
export const DEFAULT_ROUTE = POLLINATE_MINIAPP_ROUTE;
export const PORTAL_CONTEXT = 'merchant-portal-context';

export const FEATURE_FLAGS = {
  MINIAPP_WORKFLOW: 'ACQ_HUB_MINIAPP__WORKFLOW',
  MINIAPP_PEGA_FORMS: 'ACQ_HUB_MINIAPP__PEGA_FORMS',
  MINIAPP_NABC_UIM: 'ACQ_HUB_MINIAPP__NABC_UIM',
  ENSIGHTEN_SCRIPT_KILL_SWITCH: 'ACQ_HUB_ENSIGHTEN_BOOTSTRAP',
  IB_USER_LOGIN: 'ACQ_INTERNET_BANKING_USER_LOGIN'
};

export const miniapps = {
  LOGIN: {
    NABC: {
      name: 'nab-idp-usernamepassword-tokencode',
      route: '/login/nabc'
    },
    IB: {
      name: 'nab-idp-password',
      route: '/login/ib'
    },
    SELECTOR: {
      name: 'login-identity-selector',
      route: LOGIN_URL
    }
  },
  USER_INFO: {
    name: 'user-info-miniapp',
    route: '/mydetails'
  },
  MERCHANT_MENU: {
    name: 'merchant-administration-miniapp',
    route: '/merchantmenu'
  },
  POLLINATE: {
    name: 'pollinate-iframe-miniapp',
    route: POLLINATE_MINIAPP_ROUTE
  },
  WORKFLOW_MANAGEMENT: {
    name: 'epa-workflow-management-miniapp',
    route: `${CONTEXT_BASE_URL}/requesthistory`
  },
  DX_FORM: {
    name: 'hive-dx-miniapp',
    route: {
      business: `${CONTEXT_BASE_URL}/servicerequests/businessdetails`,
      store: `${CONTEXT_BASE_URL}/servicerequests/storedetails`,
      details: `${CONTEXT_BASE_URL}/servicerequests/requestdetails`
    }
  },
  NABC_USER_ADMIN: {
    name: 'nabc-user-idm-miniapp',
    route: {
      users: `${CONTEXT_BASE_URL}/admin/users`,
      create: `${CONTEXT_BASE_URL}/admin/users/create`
    }
  }
};

export const errors = {
  MINIAPP: '',
  OFFLINE: 'offline',
  NABHIVE_UNAUTHORISED: 'nabhive_error_unauthorised',
  NABHIVE_UNREGISTERED_BUSINESS: 'nabhive_error_unregistered_business',
  PAGE_NOT_FOUND: 'error_404',
  TIMEOUT: 'user_timeout',
  LOGGED_IN: 'logged_in',
  UNKNOWN: 'unknown_error',
  TOKEN_EXCHANGE: {
    jwtBadRequest: 'jwt_token_exchange_error_400',
    userInfoUnauthenticated: 'user_info_token_exchange_error_401',
    merchantUnauthenticated: 'merchant_token_exchange_error_401',
    jwtUnauthenticated: 'jwt_token_exchange_error_401',
    jwtUnauthorised: 'jwt_token_exchange_error_403',
    jwtInternalServerError: 'jwt_token_exchange_error_500'
  },
  IFRAME: {
    unauthenticated: 'iframe_error_401',
    unauthorised: 'iframe_error_403',
    notFound: 'iframe_error_404',
    internalServerError: 'iframe_error_500'
  },
  MERCHANT: {
    requestRejected: 'merchant_error_400',
    unauthenticated: 'merchant_error_401',
    unauthorised: 'merchant_error_403',
    notFound: 'merchant_error_404',
    unprocessable: 'merchant_error_422',
    resourceBusy: 'merchant_error_429',
    internalServerError: 'merchant_error_500',
    unavailable: 'merchant_error_503',
    timeout: 'merchant_error_504',
    nonexistentMid: 'merchant_error_mid'
  },
  USER_INFO: {
    requestRejected: 'user_info_error_400',
    unauthenticated: 'user_info_error_401',
    unauthorised: 'user_info_error_403',
    internalServerError: 'user_info_error_500',
    unknown: 'user_info_unknown_error'
  },
  JWT_POLLINATE: {
    notRegistered: 'not_registered_for_hive' // Notes: this is an error raised from Pollinate miniapp for missing atz claim
  }
};

export const errorsContent = {
  MINIAPP: {
    image: SystemErrorImage,
    subheading: 'This page is temporarily unavailable',
    description: errorsDescriptions.MINIAPP,
    button: 'Try again',
    buttonIcon: RefreshIcon
  },
  OFFLINE: {
    image: SystemErrorImage,
    subheading: 'Unable to connect',
    description: errorsDescriptions.OFFLINE,
    button: 'Try again',
    buttonIcon: RefreshIcon
  },
  NABHIVE_UNAUTHORISED: {
    image: LoggedoutErrorImage,
    subheading: `Sorry, we can't log you in.`,
    description: errorsDescriptions.NABHIVE_UNAUTHORISED,
    button: 'Return to Log in'
  },
  NABHIVE_UNREGISTERED_BUSINESS: {
    image: LoggedoutErrorImage,
    subheading: `Sorry, we can't log you in.`,
    description: errorsDescriptions.NABHIVE_UNREGISTERED_BUSINESS,
    button: 'Learn more about NAB Hive'
  },
  IFRAME_notFound: {
    image: SystemErrorImage,
    description: errorsDescriptions.IFRAME_notFound,
    subheading: 'Page not found'
  },
  TIMEOUT: {
    image: TimeoutErrorImage,
    subheading: 'You’ve been logged out of NAB Hive due to inactivity',
    description: errorsDescriptions.TIMEOUT,
    button: 'Login to continue'
  },
  LOGGED_IN: {
    image: LoggedoutErrorImage,
    subheading: 'You’ve been logged out of NAB Hive for security reasons',
    description: errorsDescriptions.LOGGED_IN,
    button: 'Login to continue'
  },
  IFRAME_internalServerError: {
    image: SystemErrorImage,
    subheading: 'Something has gone wrong',
    description: errorsDescriptions.IFRAME_internalServerError,
    button: 'Try again',
    buttonIcon: RefreshIcon
  },
  UNKNOWN: {
    image: SystemErrorImage,
    subheading: 'Something has gone wrong',
    description: errorsDescriptions.UNKNOWN,
    button: 'Try again',
    buttonIcon: RefreshIcon
  },
  USER_INFO_unknown: {
    image: SystemErrorImage,
    subheading: 'Something has gone wrong',
    description: errorsDescriptions.USER_INFO_unknown,
    button: 'Try again',
    buttonIcon: RefreshIcon
  },
  IFRAME_unauthorised: {
    image: LoggedoutErrorImage,
    subheading: 'You don’t have permission to view this page',
    description: errorsDescriptions.IFRAME_unauthorised,
    button: 'Return to homepage',
    buttonIcon: null
  },
  MERCHANT_nonexistentMid: {
    image: LoggedoutErrorImage,
    subheading: 'You don’t have permission to view NAB Hive',
    description: errorsDescriptions.MERCHANT_nonexistentMid,
    button: 'Return to Log in',
    buttonIcon: null
  },
  IFRAME: {
    image: LoggedoutErrorImage,
    subheading: 'You’ve been logged out of NAB Hive',
    description: errorsDescriptions.IFRAME,
    buttonIcon: null,
    button: 'Login again'
  },
  USER_INFO: {
    image: LoggedoutErrorImage,
    subheading: 'You’ve been logged out of NAB Hive',
    description: errorsDescriptions.USER_INFO,
    buttonIcon: null,
    button: 'Login again'
  }
};

export const analyticsEvents = {
  MINIAPP_LOADED: 'miniapp_loaded',
  LOGIN_PAGE_VIEW: 'login_page_view',
  LOGIN_IB_PAGE_VIEW: 'login_ib_page_view',
  LOGIN_NABC_PAGE_VIEW: 'login_nabc_page_view',
  MY_DETAILS_PAGE_VIEW: 'my_details_page_view',
  POLLINATE_INSIGHTS: 'pollinate_insights',
  SELECT_LOGIN_ID_USER_MOMENT: 'select_login_id_user_moment',
  UNKNOWN_LOGIN_METHOD_USER_MOMENT: 'unknown_login_method_user_moment',
  SELECT_LOGIN_ID_USER_MOMENT_ON_PAGES: 'select_login_id_user_moment_on_pages',
  MERCHANT_FACILITY_DROPDOWN_SELECTED: 'merchant_facility_dropdown_selected'
};

export const styling = {
  HEADER_HEIGHT: '62px',
  FOOTER_HEIGHT: '144px',
  LOGIN_MINIAPP_LEFT_PADDING: '32px',
  MENU_BAR_HEIGHT: '56px',
  MOBILE_FOOTER_HEIGHT: '248px'
};

export const TENANTS = ['nabc', 'ib'];

export const ROUTE_NAVIGATION_KEYS = [
  {
    route: POLLINATE_MINIAPP_ROUTE,
    key: 'OVERVIEW'
  },
  {
    route: '#transactions',
    key: 'PAYMENTS_SALES'
  },
  {
    route: '#settlements',
    key: 'PAYMENTS_SETTLEMENT'
  },
  {
    route: '#disputes',
    key: 'PAYMENTS_DISPUTES'
  },
  {
    route: '#plan',
    key: 'BILLING_PLANS'
  },
  {
    route: '#accounts',
    key: 'BILLING_BANK_ACCOUNTS'
  },
  {
    route: '#statements',
    key: 'BILLING_STATEMENTS'
  },
  {
    route: '#stores',
    key: 'BS_STORES'
  },
  {
    route: '#terminals',
    key: 'BS_PRODUCTS'
  },
  {
    route: '#business',
    key: 'BS_COMPANY'
  },
  {
    route: '#agreements',
    key: 'BS_AGREEMENTS'
  }
];

export default constants;
