import { removeToken } from '@nab/x-auth';
import { revokeToken } from '../api/service';
import constants, { miniapps } from './constants';
import ls from 'localstorage-slim';

export const getUserProfileFromStorage = () => {
  let userDetails: any = getUserProfile();
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  }
  const userProfile = {
    userId: userDetails ? userDetails.username : null,
    channel: isIBUser() ? 'ib' : 'nabc'
  };
  return userProfile;
};

export function saveBearerToken() {
  localStorage.setItem(constants.BEARER_TOKEN, sessionStorage.getItem(constants.BEARER_TOKEN));
}

export function saveUserIdentity() {
  localStorage.setItem(constants.USER_PROFILE, sessionStorage.getItem('identityInfo'));
}

export function getBearerToken(): string {
  return localStorage.getItem(constants.BEARER_TOKEN);
}

export function getUserProfile(): string {
  return localStorage.getItem(constants.USER_PROFILE);
}

function createLoginMethodData(value: string) {
  const date = new Date();
  const expiryDate = new Date(date.setDate(date.getDate() + constants.PREFERRED_LOGIN_METHOD_EXPIRY_DAYS));
  const methodObj = {
    expiryDate,
    value
  };
  return JSON.stringify(methodObj);
}

export function isIBUser() {
  return getPreferredLoginMethod() === 'IB';
}

export function isNABCUser() {
  return getPreferredLoginMethod() === 'NABC';
}

export function savePreferredLoginMethod(value: string) {
  localStorage.removeItem(constants.LOGIN_METHOD);
  localStorage.setItem(constants.LOGIN_METHOD, createLoginMethodData(value));
}

export function saveFacilityId(facilityId: string) {
  return ls.set(constants.FACILITY_ID, facilityId, { encrypt: true });
}

export function getPreferredLoginMethod() {
  const data = JSON.parse(localStorage.getItem(constants.LOGIN_METHOD));
  return data?.value ? data.value : null;
}

export function getPreferredLoginMethodExpiryDate() {
  const data = JSON.parse(localStorage.getItem(constants.LOGIN_METHOD));
  return data.expiryDate ? data.expiryDate : null;
}

export function getFacilityId() {
  return ls.get(constants.FACILITY_ID, { decrypt: true });
}

export function navigateToLogin() {
  if (isIBUser()) {
    window.location.assign(miniapps.LOGIN.IB.route);
  } else if (isNABCUser()) {
    window.location.assign(miniapps.LOGIN.NABC.route);
  } else {
    window.location.assign(miniapps.LOGIN.SELECTOR.route);
  }
}

export async function logout() {
  const token = getBearerToken();
  localStorage.removeItem(constants.BEARER_TOKEN);
  sessionStorage.removeItem(constants.BEARER_TOKEN);

  // Notes: this is to prevent logout being called twice issue so we only call revoke API when token exists.
  if (token) {
    await revokeToken(0, token);
    removeToken({
      tokenValue: token
    });
  }
}
