import React, { useCallback, useState, useEffect, useMemo, useContext } from 'react';
import { DafTokenExchangeError, SpaProps } from '@nab/x-spa-react';
import '@nab/x-dev-assets/module/devShellAssets/fonts.css';
import { HeaderWrapper, SpaWrapper } from './RootSpa.styles';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { getBearerToken, getFacilityId, logout } from '../../../utils/session';
import constants, { miniapps, errors, CONTEXT_BASE_URL, analyticsEvents } from '../../../utils/constants';
import { useAuth } from '../../../hooks/useAuth';
import WorkflowManagement from '../../Portal/WorkflowManagement/WorkflowManagement';
import DxForms from '../../Portal/DxForms/DxForms';
import MiniAppLoading from '../../MiniAppLoading/MiniAppLoading';
import TimeoutModal from '../../Portal/TimeoutModal/TimeoutModal';
import { pushAnalyticsEvent, getPageNameForAnalytics } from '../../../utils/analytics';
import { NavManagerMenuItem } from '../../Header/PollinateMenu/PollinateMenu';
import Pollinate from '../../Portal/Pollinate/Pollinate';
import UserInfo from '../../Portal/UserInfo/UserInfo';
import MiniAppError from '../../Error/MiniAppError';
import { ErrorContextConfig } from '../../../utils/config/commonIndexConfig';
import { ShellContext } from '../GlobalSpa/GlobalSpa';
import ConfirmModal from '../../Portal/ConfirmModal/ConfirmModal';
import UsersPermissionsAdmin from '../../Portal/UIM/UsersPermissionsAdmin';
import useLaunchDarkly from '../../../hooks/useLaunchDarkly';
import { useMediaQuery } from '@nab/nui-react';

export interface MerchantData {
  merchantId: string;
  activationStatus: string;
  tradingName: string;
}

interface RootSpaProps extends SpaProps {
  rootError?: string | object;
}

const RootSpa = ({ context, activeAppInstance, initError, routeError, actions, rootError }: RootSpaProps) => {
  const { loading, isAuthenticated } = useAuth();
  const [error, setError] = useState<string>(rootError as string);
  const [hasTokenExchangeError, setTokenExchangeError] = useState(false);
  const [hasHiveAccessError, setHiveAccessError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [startTime, setStartTime] = useState(Date.now());
  const [pilInactivityMessageReceived, setPilInactivityMessageReceived] = useState(false);
  const pollinateMenu: NavManagerMenuItem[] = context?.menu;
  const { merchantsData, currentRoute: currentPageHash, updatePageTitle } = useContext(ShellContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isHeaderVisible, setHeaderVisible] = useState(false);
  const { launchDarklyError } = useLaunchDarkly();
  const isLargeScreen = useMediaQuery({ minWidth: 'lg' });

  function completePollinateMiniappTokenCheck() {
    setHeaderVisible(true);
  }

  async function logOutFunction() {
    await logout();
  }

  function onUIMMiniappNavigated() {
    console.log('Url changed - reset timeout');
    setStartTime(Date.now());
  }

  useEffect(() => {
    if (context) {
      const { error } = context as ErrorContextConfig;

      if (error === DafTokenExchangeError.INVALID_SEED_TOKEN) {
        setTokenExchangeError(true);
        setError(errors.TOKEN_EXCHANGE.jwtBadRequest);
      } else if (
        error === errors.NABHIVE_UNAUTHORISED ||
        error === errors.NABHIVE_UNREGISTERED_BUSINESS ||
        error === errors.JWT_POLLINATE.notRegistered
      ) {
        if (error === errors.NABHIVE_UNAUTHORISED) {
          pushAnalyticsEvent(errors.NABHIVE_UNAUTHORISED);
        } else if (error === errors.JWT_POLLINATE.notRegistered) {
          pushAnalyticsEvent(errors.JWT_POLLINATE.notRegistered);
        } else {
          pushAnalyticsEvent(errors.NABHIVE_UNREGISTERED_BUSINESS);
        }
        setHiveAccessError(true);
        setError(error);
        logOutFunction();
      }
    }
  }, [context, setError, setTokenExchangeError]);

  const resetTimeout = useCallback(() => {
    if (!openModal) {
      setStartTime(Date.now());
    }
  }, [openModal]);

  function showPollinateInactivityModal() {
    setOpenModal(true);

    // console.log('Timeout: Display timeout warning modal from Pollinate page');
    setPilInactivityMessageReceived(true);
  }

  useEffect(() => {
    switch (error) {
      case errors.PAGE_NOT_FOUND:
        pushAnalyticsEvent(errors.PAGE_NOT_FOUND, window.location.pathname + window.location.hash);
        break;
      case errors.USER_INFO.requestRejected:
      case errors.USER_INFO.unauthenticated:
      case errors.USER_INFO.unauthorised:
      case errors.USER_INFO.internalServerError:
      case errors.USER_INFO.unknown:
      case errors.MERCHANT.requestRejected:
      case errors.MERCHANT.unauthenticated:
      case errors.MERCHANT.unauthorised:
      case errors.MERCHANT.notFound:
      case errors.MERCHANT.unprocessable:
      case errors.MERCHANT.resourceBusy:
      case errors.MERCHANT.internalServerError:
      case errors.MERCHANT.unavailable:
      case errors.MERCHANT.timeout:
      case errors.MERCHANT.nonexistentMid:
      case errors.TOKEN_EXCHANGE.jwtBadRequest:
      case errors.TOKEN_EXCHANGE.jwtUnauthorised:
      case errors.TOKEN_EXCHANGE.userInfoUnauthenticated:
      case errors.TOKEN_EXCHANGE.merchantUnauthenticated:
      case errors.TOKEN_EXCHANGE.jwtUnauthenticated:
      case errors.NABHIVE_UNAUTHORISED:
      case errors.NABHIVE_UNREGISTERED_BUSINESS:
      case errors.JWT_POLLINATE.notRegistered:
        pushAnalyticsEvent(error);
        break;
    }
  }, [error]);

  function isLogoutError(error: string) {
    return (
      error === errors.LOGGED_IN ||
      error === errors.TOKEN_EXCHANGE.jwtBadRequest ||
      error === errors.TOKEN_EXCHANGE.merchantUnauthenticated ||
      error === errors.TOKEN_EXCHANGE.userInfoUnauthenticated ||
      error === errors.TOKEN_EXCHANGE.jwtUnauthenticated ||
      error === errors.TOKEN_EXCHANGE.jwtUnauthorised ||
      error === errors.IFRAME.unauthenticated ||
      error === errors.MERCHANT.requestRejected ||
      error === errors.MERCHANT.unauthenticated ||
      error === errors.MERCHANT.unauthorised ||
      error === errors.MERCHANT.notFound ||
      error === errors.MERCHANT.unprocessable ||
      error === errors.MERCHANT.nonexistentMid ||
      // Notes: Also treat error 400, 401 and 403 from user info mini-app as logout errors
      error === errors.USER_INFO.requestRejected ||
      error === errors.USER_INFO.unauthenticated ||
      error === errors.USER_INFO.unauthorised ||
      // Notes: Specific error for NABC and IB not onboarded business
      error === errors.NABHIVE_UNAUTHORISED ||
      error === errors.NABHIVE_UNREGISTERED_BUSINESS ||
      error === errors.JWT_POLLINATE.notRegistered
    );
  }

  useEffect(() => {
    if (!activeAppInstance) {
      return;
    }
  }, [activeAppInstance]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeAppInstance]);

  const events = ['click', 'keydown'];

  // Log user out after period of inactivity
  const timeout = async interval => {
    const pageName =
      activeAppInstance?.app.id === miniapps.USER_INFO.name
        ? 'my-details'
        : getPageNameForAnalytics(window.location.hash.replace('#', ''), pollinateMenu);
    pushAnalyticsEvent(errors.TIMEOUT, pageName);
    setError(errors.TIMEOUT);

    // Make sure we clear interval and any event listeners
    clearEventListeners(interval);
    await logout();
    setOpenModal(false);
    await actions.dispatchEvent(constants.POLLINATE_MINIAPP_LOGOUT);
  };

  // Note: clear all the event listeners
  const clearEventListeners = interval => {
    if (interval) {
      clearInterval(interval);
    }

    // Notes: Only remove user event listeners if the monitoring flag is turned "on"
    if (constants.USER_INACTIVITY_EVENT_MONITORING) {
      for (const i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    }
  };

  function mobileMenuOpen() {
    setIsMobileMenuOpen(true);
  }

  function mobileMenuClose() {
    setIsMobileMenuOpen(false);
  }

  useEffect(() => {
    let interval;

    async function userInfoLinkClicked() {
      await actions.navigate(`${CONTEXT_BASE_URL}${miniapps.USER_INFO.route}`);
      setError(null);
    }

    async function setShellRenderErrors(recievedError: string) {
      if (isLogoutError(recievedError)) {
        await actions.dispatchEvent(constants.POLLINATE_MINIAPP_LOGOUT);
        !isLargeScreen && await actions.dispatchEvent(constants.MOBILE_MENU_ERROR);
        await logout();
        setError(recievedError);
      } else if (!navigator.onLine) {
        setError(errors.OFFLINE);
        // Does not overwrite other errors
      } else if(!error) {
        setError(recievedError);
      }

      // Make sure we clear interval and any event listeners
      clearEventListeners(interval);
    }

    const checkInterval = () => {
      // should not run 30 min timer on pollinate
      if (activeAppInstance && activeAppInstance.app.id === miniapps.POLLINATE.name) {
        // console.log('Timeout: interval check');

        // When pil inactivity recieved, set timer to 2 min.
        if (pilInactivityMessageReceived) {
          // console.log('Inactivity message received');
          const remainingTime = (constants.POLLINATE_TIMEOUT_INTERVAL - (Date.now() - startTime)) / 1000 / 60;
          // console.log(`Timeout: Pollinate: interval check: Remaining time is ${remainingTime}`);

          // Timeout error after 2 minutes after timeout modal appears
          if (remainingTime <= 0) {
            // console.log('Timeout: Log user out from Pollinate page');
            timeout(interval);
            setPilInactivityMessageReceived(false);
          }
        }
      } else {
        const remainingTime = (constants.TIMEOUT_INTERVAL - (Date.now() - startTime)) / 1000 / 60;
        // console.log(`Timeout: interval check: Remaining time is ${remainingTime}`);

        // Timeout error if 30 mins of inactivity
        if (remainingTime <= 0) {
          // console.log('Timeout: Log user out from shell page');
          timeout(interval);
        }

        // Should send warning when remaining time is 2 minutes
        else if (remainingTime <= 2) {
          // console.log('Timeout: Display timeout warning modal from shell page');
          setOpenModal(true);
        }
      }
    };

    if (activeAppInstance) {
      interval = setInterval(checkInterval, 10000);
    }

    // Get merchant data from miniapp
    actions.addEventListener(constants.RENDER_ERROR, setShellRenderErrors);
    actions.addEventListener(constants.USERINFO_LINK_MINIAPP_CLICKED, userInfoLinkClicked);
    actions.addEventListener(constants.OPEN_CONFIRM_MODAL, setOpenConfirmModal);
    actions.addEventListener(constants.MOBILE_MENU_OPEN, mobileMenuOpen);
    actions.addEventListener(constants.MOBILE_MENU_CLOSE, mobileMenuClose);
    actions.addEventListener(constants.POLLINATE_MINIAPP_ROLE_CHECK_COMPLETED, completePollinateMiniappTokenCheck);
    actions.addEventListener(constants.UAM_MINIAPP_NAVIGATED, onUIMMiniappNavigated);

    if (activeAppInstance?.app.id === miniapps.POLLINATE.name) {
      actions.addEventListener(constants.POLLINATE_MINIAPP_INACTIVITY, showPollinateInactivityModal);
    } else if (constants.USER_INACTIVITY_EVENT_MONITORING) {
      // Notes: Only remove user event listeners if the monitoring flag is turned "on"
      for (const i in events) {
        window.addEventListener(events[i], resetTimeout);
      }
    }

    return () => {
      actions.removeEventListener(constants.RENDER_ERROR, setShellRenderErrors);
      actions.removeEventListener(constants.USERINFO_LINK_MINIAPP_CLICKED, userInfoLinkClicked);
      actions.removeEventListener(constants.OPEN_CONFIRM_MODAL, setOpenConfirmModal);
      actions.removeEventListener(constants.MOBILE_MENU_OPEN, mobileMenuOpen);
      actions.removeEventListener(constants.MOBILE_MENU_CLOSE, mobileMenuClose);
      actions.removeEventListener(constants.POLLINATE_MINIAPP_ROLE_CHECK_COMPLETED, completePollinateMiniappTokenCheck);
      actions.removeEventListener(constants.UAM_MINIAPP_NAVIGATED, onUIMMiniappNavigated);

      if (activeAppInstance?.app.id === miniapps.POLLINATE.name) {
        actions.removeEventListener(constants.POLLINATE_MINIAPP_INACTIVITY, showPollinateInactivityModal);
      }
      if (interval) {
        clearEventListeners(interval);
      }
    };
  }, [activeAppInstance, actions, events, pilInactivityMessageReceived, clearEventListeners, resetTimeout, timeout, isMobileMenuOpen]);

  function dispatchMerchantFacilitySelectedAnalytics(facilityId) {
    if (getFacilityId() && getFacilityId() !== facilityId) {
      const pageName = getPageNameForAnalytics(window.location.hash.replace('#', ''), pollinateMenu);
      for (const merchant in merchantsData) {
        if (merchantsData[merchant].merchantId === facilityId) {
          pushAnalyticsEvent(analyticsEvents.MERCHANT_FACILITY_DROPDOWN_SELECTED, pageName, null, merchantsData[merchant].tradingName);
        }
      }
    }
  }

  useEffect(() => {
    actions.addEventListener(constants.SHELL_MERCHANT_ID_CHANGED, dispatchMerchantFacilitySelectedAnalytics);

    return () => {
      actions.removeEventListener(constants.SHELL_MERCHANT_ID_CHANGED, dispatchMerchantFacilitySelectedAnalytics);
    };
  }, [merchantsData]);

  async function handleBackNav(e) {
    if (activeAppInstance && activeAppInstance?.app.id !== miniapps.POLLINATE.name) {
      // If the path is different, navigate to the previous path
      const isOnIncorrectPage = !window.location.pathname.includes(activeAppInstance?.app.route);

      if (isOnIncorrectPage) {
        (window.document.activeElement as HTMLElement)?.blur();
      }
    }
  }

  // Notes: Handling the back button behaviour
  useEffect(() => {
    window.addEventListener('popstate', handleBackNav);

    return () => {
      window.removeEventListener('popstate', handleBackNav);
    };
  }, [activeAppInstance?.app.id]);

  useEffect(() => {
    setStartTime(Date.now());
  }, [activeAppInstance, pilInactivityMessageReceived]);

  // Note: render mini-app with useMemo to make sure we don't do unnecessary re-renders
  const renderMiniapp = useMemo(() => {
    if (loading && !isAuthenticated) {
      return <MiniAppLoading />;
    }

    //handle LD error when navigating
    if (launchDarklyError) {
      return (
          <MiniAppError initError={initError} routeError={routeError} showPollinateMenu={false} />
      )
    }

    async function logoutCall() {
      await actions.dispatchEvent(constants.POLLINATE_MINIAPP_LOGOUT);
      await logout();
    }

    // Case: if shellRenderError isn't called, logout if logout error
    if (isLogoutError(error) && getBearerToken()) {
      logoutCall();
    }

    if (!hasTokenExchangeError && !hasHiveAccessError) {
      if (routeError) {
        if (!isAuthenticated) {
          return <MiniAppLoading />;
        }
        if (activeAppInstance?.route?.unMatchedPath && activeAppInstance?.app.id !== miniapps.DX_FORM.name) {
          return <MiniAppError routeError={routeError} />;
        }
        setError(errors.PAGE_NOT_FOUND);

        // Make sure we clear interval and any event listeners
        clearEventListeners(null);
      } else if (initError) {
        const isBeingRedirected = localStorage.getItem('redirectionFrom');

        if (isBeingRedirected === null) {
          return <MiniAppError initError={initError} />;
        } else {
          // Note: if user is being redirected, render nothing to get around initError while fetching auth context
          localStorage.removeItem('redirectionFrom');
        }
      }
    }

    switch (activeAppInstance?.app.id) {
      case miniapps.WORKFLOW_MANAGEMENT.name:
        return <WorkflowManagement error={error} setError={setError} />;
      case miniapps.DX_FORM.name:
        return <DxForms error={error} setError={setError} />;
      case miniapps.POLLINATE.name:
        return <Pollinate error={error} setError={setError} />;
      case miniapps.USER_INFO.name:
        return <UserInfo error={error} setError={setError} />;
      case miniapps.NABC_USER_ADMIN.name:
        return <UsersPermissionsAdmin error={error} setError={setError} />;
      default:
        return <Pollinate error={error} setError={setError} />;
    }
  }, [activeAppInstance, routeError, initError, error, merchantsData, loading, isAuthenticated, pilInactivityMessageReceived, launchDarklyError]);

  function shouldShowMenu(): boolean {
    // return false if polliante menu is empty
    if (!pollinateMenu || launchDarklyError) {
      return false;
    }
    if (activeAppInstance?.app.id === miniapps.POLLINATE.name) {
      return (
        getBearerToken() &&
        (!error ||
          error === errors.PAGE_NOT_FOUND ||
          error === errors.IFRAME.unauthorised ||
          error === errors.IFRAME.notFound ||
          error === errors.IFRAME.internalServerError)
      );
    } else {
      return getBearerToken() && !error;
    }
  }

  // If not in pollinate route or error exists, sets the header to visible
  useEffect(() => {
    if (error || !window.location.pathname.includes(miniapps.POLLINATE.route)) {
      setHeaderVisible(true);
    }
  }, [error]);

  function getCurrentRoute() {
    switch (activeAppInstance?.app.id) {
      case miniapps.WORKFLOW_MANAGEMENT.name:
      case miniapps.DX_FORM.name:
      case miniapps.NABC_USER_ADMIN.name:
      case miniapps.USER_INFO.name:
        return window.location.pathname;
      case miniapps.POLLINATE.name:
        return currentPageHash;
      default:
        return null;
    }
  }

  useEffect(() => {
    const pathName = window.location.pathname.replace('/auth', '');

    const pageTitle = getPageTitle(pathName);
    if (pageTitle) {
      updatePageTitle(pageTitle);
    }
  }, [window.location.pathname, window.location.hash]);

  return (
    <SpaWrapper className={`${isMobileMenuOpen ? 'isMobileMenuOpen' : ''}`}>
      {openModal && <TimeoutModal setError={setError} />}
      {openConfirmModal && <ConfirmModal setOpenModal={setOpenConfirmModal} />}

      <HeaderWrapper className={`${!isHeaderVisible ? 'isHidden' : ''}`}>
        <Header setError={setError} currentRoute={getCurrentRoute()} showPollinateMenu={shouldShowMenu()} />
      </HeaderWrapper>
      {renderMiniapp}
      <Footer />
    </SpaWrapper>
  );
};

export const getPageTitleFromHash = hash => {
  switch (hash) {
    case '#transactions':
      return 'Transactions';
    case '#settlements':
      return 'Settlements';
    case '#disputes':
      return 'Disputes';
    case '#plan':
      return 'Service plan';
    case '#accounts':
      return 'Bank accounts';
    case '#statements':
      return 'Statements';
    case '#stores':
      return 'Stores';
    case '#terminals':
      return 'Terminals';
    case '#business':
      return 'Business Information';
    case '#agreements':
      return 'Agreements';
    default:
      return null;
  }
};

export const getPageTitle = path => {
  switch (path) {
    case '/portal':
      const pageHash = window.location.hash;
      const getPageHashTitle = getPageTitleFromHash(pageHash);
      return getPageHashTitle ? getPageHashTitle : 'Overview';
    case '/mydetails':
      return 'My details';
    case '/servicerequests/businessdetails':
      return 'Update business details';
    case '/servicerequests/storedetails':
      return 'Update store details';
    case '/servicerequests/requestdetails':
      return 'Service request details';
    case '/requesthistory':
      return 'Request History';
    default:
      return null;
  }
};

export default RootSpa;

