import React, { useContext } from 'react';
import { H1, H2, Button } from '@nab/nui-react';
import { ErrorImage, ErrorWrapper } from './Error.styles';
import { errors, errorsContent, CONTEXT_BASE_URL, DEFAULT_ROUTE } from '../../utils/constants';
import { getBearerToken, isIBUser, navigateToLogin } from '../../utils/session';
import { ReactSpaContext } from '@nab/x-spa-react';
import { AppViewport } from '../Spa/RootSpa/RootSpa.styles';

export function createError(error: string, heading?: string): ErrorData {
  switch (error) {
    case errors.MINIAPP:
      return {
        id: error,
        heading: heading,
        ...errorsContent.MINIAPP
      };
    case errors.OFFLINE:
      return {
        id: error,
        heading: heading,
        ...errorsContent.OFFLINE
      };
    case errors.NABHIVE_UNAUTHORISED:
      return {
        id: error,
        heading: '',
        ...errorsContent.NABHIVE_UNAUTHORISED
      };
    case errors.NABHIVE_UNREGISTERED_BUSINESS:
      return {
        id: error,
        heading: '',
        ...errorsContent.NABHIVE_UNREGISTERED_BUSINESS
      };
    case errors.PAGE_NOT_FOUND:
    case errors.IFRAME.notFound:
      return {
        id: error,
        heading: 'Error 404',
        button: pageNotFoundButton(),
        ...errorsContent.IFRAME_notFound
      };
    case errors.TIMEOUT:
      return {
        id: error,
        heading: 'NAB Hive',
        ...errorsContent.TIMEOUT
      };
    case errors.LOGGED_IN:
      return {
        id: error,
        heading: 'NAB Hive',
        ...errorsContent.LOGGED_IN
      };
    case errors.TOKEN_EXCHANGE.jwtInternalServerError:
    case errors.IFRAME.internalServerError:
      return {
        id: error,
        heading: 'Error 500',
        ...errorsContent.IFRAME_internalServerError
      };
    case errors.MERCHANT.resourceBusy:
    case errors.MERCHANT.internalServerError:
    case errors.USER_INFO.internalServerError:
    case errors.MERCHANT.unavailable:
    case errors.MERCHANT.timeout:
    case errors.UNKNOWN:
      return {
        id: error,
        heading: 'NAB Hive',
        ...errorsContent.UNKNOWN
      };
    case errors.USER_INFO.unknown:
      return {
        id: error,
        heading: 'My Details',
        ...errorsContent.USER_INFO_unknown
      };
    case errors.IFRAME.unauthorised:
      return {
        id: error,
        heading: 'NAB Hive',
        ...errorsContent.IFRAME_unauthorised
      };
    case errors.MERCHANT.nonexistentMid:
      return {
        id: error,
        heading: 'NAB Hive',
        ...errorsContent.MERCHANT_nonexistentMid
      };
    case errors.JWT_POLLINATE.notRegistered:
      if (isIBUser()) {
        return {
          id: error,
          heading: '',
          ...errorsContent.NABHIVE_UNREGISTERED_BUSINESS
        };
      }
      return {
        id: error,
        heading: '',
        ...errorsContent.NABHIVE_UNAUTHORISED
      };
    default:
      break;
  }

  // if errors are not explicitly declared in case statement, they'll default to below
  if (
    Object.values(errors.MERCHANT).includes(error) ||
    Object.values(errors.TOKEN_EXCHANGE).includes(error) ||
    Object.values(errors.IFRAME).includes(error)
  ) {
    return {
      id: error,
      heading: 'NAB Hive',
      ...errorsContent.IFRAME
    };
  } else if (Object.values(errors.USER_INFO).includes(error)) {
    return {
      id: error,
      heading: 'NAB Hive',
      ...errorsContent.USER_INFO
    };
  }
}

function pageNotFoundButton() {
  if (getBearerToken()) {
    return 'Return to homepage';
  }
  return 'Return to Log in';
}

interface ErrorData {
  id: string;
  heading: string;
  image: string;
  subheading: string;
  buttonIcon?: any;
  description: JSX.Element;
  button: string;
}

interface ErrorProps {
  error: ErrorData;
  setError?: (value: string) => void;
  showNavigation?: boolean;
}

export function Error({ error, setError, showNavigation = false }: ErrorProps) {
  const { actions } = useContext(ReactSpaContext);

  async function buttonClicked() {
    switch (error.id) {
      case errors.PAGE_NOT_FOUND:
        if (getBearerToken()) {
          window.location.hash = '';
          await actions.navigate(`${CONTEXT_BASE_URL}${DEFAULT_ROUTE}`);
          setError?.(null);
        } else {
          navigateToLogin();
        }
        break;
      case errors.OFFLINE:
        if (navigator.onLine) {
          setError?.(null);
        }
        return;
      case errors.TOKEN_EXCHANGE.jwtBadRequest:
      case errors.TOKEN_EXCHANGE.merchantUnauthenticated:
      case errors.TOKEN_EXCHANGE.userInfoUnauthenticated:
      case errors.TOKEN_EXCHANGE.jwtUnauthenticated:
      case errors.TOKEN_EXCHANGE.jwtUnauthorised:
      case errors.IFRAME.unauthenticated:
      case errors.MERCHANT.requestRejected:
      case errors.MERCHANT.unauthenticated:
      case errors.MERCHANT.unauthorised:
      case errors.MERCHANT.notFound:
      case errors.MERCHANT.unprocessable:
      case errors.MERCHANT.nonexistentMid:
      case errors.USER_INFO.requestRejected:
      case errors.USER_INFO.unauthenticated:
      case errors.USER_INFO.unauthorised:
      case errors.TIMEOUT:
        navigateToLogin();
        break;
      case errors.IFRAME.unauthorised:
      case errors.IFRAME.notFound:
        window.location.hash = '';
        break;
      case errors.IFRAME.internalServerError:
        window.dispatchEvent(new HashChangeEvent('hashchange'));
        setError?.(null);
        break;
      case errors.JWT_POLLINATE.notRegistered:
        if (isIBUser()) {
          window.location.href = 'https://www.nab.com.au/business/payments-and-merchants/nab-hive';
        } else {
          navigateToLogin();
        }
        break;
      case errors.NABHIVE_UNAUTHORISED:
        navigateToLogin();
        break;
      case errors.NABHIVE_UNREGISTERED_BUSINESS:
        window.location.href = 'https://www.nab.com.au/business/payments-and-merchants/nab-hive';
        break;
      default:
        window.location.reload();
    }
  }

  const errorContainer = error ? (
    <ErrorWrapper id="Error_display" data-testid={error.id === errors.MINIAPP ? 'miniappError' : ''}>
      <H1 id="Error_heading" tabIndex="-1">
        {error.heading}
      </H1>
      <ErrorImage src={error.image} id="Error_image" alt="" />
      <H2 id="Error_message_h2">{error.subheading}</H2>
      <div id="Error_message_desc">{error.description}</div>
      <Button
        id="Error_button"
        data-testid="Action"
        variant="primary"
        icon={error.buttonIcon}
        iconToRight
        label={error.button}
        onClick={() => buttonClicked()}
      />
    </ErrorWrapper>
  ) : (
    <></>
  );

  return showNavigation ? (
    <>
      <AppViewport id="appViewPort">{errorContainer}</AppViewport>
    </>
  ) : (
    errorContainer
  );
}

export default Error;
