import constants, { analyticsEvents, errors, miniapps } from './constants';
import { NavManagerMenuItem } from '../components/Header/PollinateMenu/PollinateMenu';
import { isIBUser, isNABCUser } from './session';

interface AnalyticsData {
  name?: string;
  action?: string;
  componentName?: string;
  channel?: string;
  errorType?: string;
  error?: string;
}

function createData(event: string, pageName?: string, eventData?: AnalyticsData, merchantName?: string): AnalyticsData {
  switch (event) {
    case analyticsEvents.MINIAPP_LOADED:
      switch (pageName) {
        case miniapps.WORKFLOW_MANAGEMENT.route:
          return {
            name: 'service-request:request-history',
            componentName: 'Merchant Portal Request History miniapp',
            action: 'miniapp-loaded'
          };
        case miniapps.DX_FORM.route.business:
          return {
            name: 'service-request:update-business-details',
            componentName: 'Merchant Portal Update Business Details miniapp',
            action: 'miniapp-loaded'
          };
        case miniapps.DX_FORM.route.store:
          return {
            name: 'service-request:update-store-details',
            componentName: 'Merchant Portal Update Store Details miniapp',
            action: 'miniapp-loaded'
          };
        case miniapps.MERCHANT_MENU.route:
          return {
            componentName: 'Merchant Portal Merchant Facility Selector miniapp',
            action: 'miniapp-loaded'
          };
        default:
          return {
            name: constants.NAB_HIVE_MERCHANT_PORTAL
          };
      }
    case analyticsEvents.LOGIN_PAGE_VIEW:
      return {
        componentName: 'NAB Hive Merchant Portal',
        name: 'login',
        action: 'page-view'
      };
    case analyticsEvents.LOGIN_IB_PAGE_VIEW:
      return {
        componentName: 'NAB Hive Merchant Portal',
        name: 'login:nab-internet-banking',
        action: 'page-view'
      };
    case analyticsEvents.LOGIN_NABC_PAGE_VIEW:
      return {
        componentName: 'NAB Hive Merchant Portal',
        name: 'login:nab-connect',
        action: 'page-view'
      };
    case analyticsEvents.MY_DETAILS_PAGE_VIEW:
      return {
        componentName: 'NAB Hive Merchant Portal',
        name: 'my-details',
        action: 'page-view'
      };
    case analyticsEvents.POLLINATE_INSIGHTS:
      delete eventData['eventType'];
      return eventData;
    case analyticsEvents.SELECT_LOGIN_ID_USER_MOMENT:
      return {
        componentName: 'NAB Hive Merchant Portal',
        name: `login|${pageName === 'nab-connect' ? 'Login using NAB Connect' : 'Login using Internet Banking'}`
      };
    case analyticsEvents.SELECT_LOGIN_ID_USER_MOMENT_ON_PAGES:
      return {
        componentName: 'NAB Hive Merchant Portal',
        name: `login:${pageName}|${pageName === 'nab-connect' ? 'Login using Internet Banking' : 'Login using NAB Connect'}`
      };
    case analyticsEvents.UNKNOWN_LOGIN_METHOD_USER_MOMENT:
      return {
        componentName: 'NAB Hive Merchant Portal',
        name: 'login|Not sure which login option to use?'
      };
    case analyticsEvents.MERCHANT_FACILITY_DROPDOWN_SELECTED:
      return {
        action: 'page-view',
        componentName: 'Merchant Portal Merchant Facility Selector miniapp',
        name: `${pageName}|merchant-facility-filter-applied`,
        filter: `${merchantName}`
      };
    case errors.MINIAPP:
      return {
        componentName: 'NAB Hive Merchant Portal',
        name: `${pageName}`,
        action: 'error-displayed',
        errorType: 'screen',
        error: 'We’ve encountered an unexpected problem. Please wait a few moments and try again.'
      };
    case errors.LOGGED_IN:
      return {
        componentName: 'NAB Hive Merchant Portal',
        name: 'login:forced-login-error',
        error: 'You’ve been logged out of NAB Hive for security reasons',
        action: 'error-displayed',
        errorType: 'screen'
      };
    case errors.OFFLINE:
      return {
        componentName: 'NAB Hive Merchant Portal',
        name: `${pageName}:no-internet-connection-error`,
        error: 'We are unable to connect you to NAB Hive. Please check your internet connection and try again.',
        action: 'error-displayed',
        errorType: 'screen'
      };
    case errors.PAGE_NOT_FOUND:
      return {
        componentName: 'NAB Hive Merchant Portal',
        name: `${pageName}:page-not-found-error`,
        error: 'We can’t find the page you were looking for.',
        action: 'error-displayed',
        errorType: 'screen',
        errorCode: '404'
      };
    case errors.TIMEOUT:
      return {
        componentName: 'NAB Hive Merchant Portal',
        name: `${pageName}:session-timeout`,
        error: 'You’ve been logged out of NAB Hive due to inactivity.',
        action: 'error-displayed',
        errorType: 'screen'
      };
    case errors.USER_INFO.requestRejected:
    case errors.USER_INFO.unauthenticated:
    case errors.USER_INFO.unauthorised:
    case errors.USER_INFO.internalServerError:
    case errors.USER_INFO.unknown:
      return {
        componentName: 'NAB Hive Merchant Portal',
        name: 'mydetails:miniapp-load-error',
        error: 'We’ve encountered an unexpected problem. Please wait a few moments and try again.',
        action: 'error-displayed',
        errorType: 'screen',
        errorCode: event.replace(/^\D+/g, '')
      };
    case errors.MERCHANT.requestRejected:
    case errors.MERCHANT.unauthenticated:
    case errors.MERCHANT.unauthorised:
    case errors.MERCHANT.notFound:
    case errors.MERCHANT.unprocessable:
      return {
        componentName: 'NAB Hive Merchant Portal',
        name: 'merchant-admin-error',
        error: 'An unexpected error occurred and you have been logged out. Please contact Merchant Support Centre on 1300 755 287 or login again.',
        action: 'error-displayed',
        errorType: 'screen',
        errorCode: event.replace(/^\D+/g, '')
      };
    case errors.MERCHANT.resourceBusy:
    case errors.MERCHANT.internalServerError:
    case errors.MERCHANT.unavailable:
    case errors.MERCHANT.timeout:
    case errors.MERCHANT.nonexistentMid:
      return {
        componentName: 'NAB Hive Merchant Portal',
        name: 'merchant-admin-error',
        error: 'An error occurred and your request couldn’t be completed. Please wait a few moments and try again.',
        action: 'error-displayed',
        errorType: 'screen',
        errorCode: event.replace(/^\D+/g, '')
      };
    case errors.TOKEN_EXCHANGE.jwtBadRequest:
    case errors.TOKEN_EXCHANGE.jwtUnauthenticated:
    case errors.TOKEN_EXCHANGE.jwtUnauthorised:
      return {
        componentName: 'NAB Hive Merchant Portal',
        name: 'pollinate-jwt-token-error',
        error: 'An unexpected error occurred and you have been logged out. Please contact Merchant Support Centre on 1300 755 287 or login again.',
        action: 'error-displayed',
        errorType: 'screen',
        errorCode: event.replace(/^\D+/g, '')
      };
    case errors.TOKEN_EXCHANGE.merchantUnauthenticated:
      return {
        componentName: 'NAB Hive Merchant Portal',
        name: 'merchant-admin-mini-app-token-error',
        error: 'An unexpected error occurred and you have been logged out. Please contact Merchant Support Centre on 1300 755 287 or login again.',
        action: 'error-displayed',
        errorType: 'screen',
        errorCode: event.replace(/^\D+/g, '')
      };
    case errors.TOKEN_EXCHANGE.userInfoUnauthenticated:
      return {
        componentName: 'NAB Hive Merchant Portal',
        name: 'user-info-mini-app-token-error',
        error: 'An unexpected error occurred and you have been logged out. Please contact Merchant Support Centre on 1300 755 287 or login again.',
        action: 'error-displayed',
        errorType: 'screen',
        errorCode: event.replace(/^\D+/g, '')
      };
    case errors.NABHIVE_UNAUTHORISED:
      return {
        componentName: 'NAB Hive Merchant Portal',
        name: `login:${isNABCUser() ? `nab-connect` : 'nab-internet-banking'}:login-error`,
        errorType: 'screen',
        error: `You don't have access to view NAB Hive with those login details.`,
        action: 'error-displayed'
      };
    case errors.NABHIVE_UNREGISTERED_BUSINESS:
      return {
        componentName: 'NAB Hive Merchant Portal',
        name: `login:${isNABCUser() ? `nab-connect` : 'nab-internet-banking'}:login-error`,
        errorType: 'screen',
        error: `Your business hasn't been registered for NAB Hive just yet.`,
        action: 'error-displayed'
      };
    case errors.JWT_POLLINATE.notRegistered:
      if (isIBUser()) {
        return {
          componentName: 'NAB Hive Merchant Portal',
          name: `login:nab-internet-banking:login-error`,
          errorType: 'screen',
          error: `Your business hasn't been registered for NAB Hive just yet.`,
          action: 'error-displayed'
        };
      } else {
        return {
          componentName: 'NAB Hive Merchant Portal',
          name: `login:nab-connect:login-error`,
          errorType: 'screen',
          error: `You don't have access to view NAB Hive with those login details.`,
          action: 'error-displayed'
        };
      }
  }
}

function createEvent(event: string, pageName?: string, eventData?: AnalyticsData, merchantName?: string) {
  const eventType = eventData ? eventData['eventType'] : 'page-view';
  const data = createData(event, pageName, eventData, merchantName);
  data.channel = window['digitalData'].components?.channel;

  switch (event) {
    case analyticsEvents.MINIAPP_LOADED:
      return {
        eventType: 'miniapp-loaded',
        data: data
      };
    case analyticsEvents.SELECT_LOGIN_ID_USER_MOMENT:
    case analyticsEvents.UNKNOWN_LOGIN_METHOD_USER_MOMENT:
    case analyticsEvents.SELECT_LOGIN_ID_USER_MOMENT_ON_PAGES:
      return {
        eventType: 'user-moment',
        data: data
      };
    default:
      return {
        eventType: eventType,
        data: data
      };
  }
}

export function pushAnalyticsEvent(event: string, pageName?: string, eventData?: Record<string, any>, merchantName?: string) {
  window['digitalData'].event.push(createEvent(event, pageName, eventData, merchantName));
}

export function getPageNameForAnalytics(currentRoute: string, menu: NavManagerMenuItem[]) {
  let pageName = '';
  menu?.some(function(item) {
    if (item.route === currentRoute) {
      pageName = item.route === '' ? 'account-summary' : item.label;
      return true;
    } else if (item.children) {
      item.children.some(function(child) {
        const childRoute = child.route.replace('/portal/#', '');
        if (childRoute === currentRoute) {
          pageName = `${item.label} ${child.label}`;
          return true;
        }
        return false;
      });
    }
    return false;
  });

  return pageName.replace(/ /g, '-').toLowerCase();
}
